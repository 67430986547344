import { useNavigate } from "react-router";
import { auth } from "../externals/firebase";

const useAuth = () => {
  const navigate = useNavigate();

  auth.onAuthStateChanged((user) => {
    if (!user) {
      navigate('/login');
    }
  })

  return {
  };
};

export default useAuth;
