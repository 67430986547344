import { Modal, Form, Row, Col, Select, Input, InputNumber } from "antd";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import { ModalContextProps } from "../../../../hooks/useModal";
import { updateMember } from "../../../../repositories/member/member.firebase.repository";
import { Class, MemberStatus } from "../../../../types/enum";

interface UpdateUserModalProps {
  context: ModalContextProps;
  onUpdate?: () => void;
}

const UpdateUserModal = ({ context, onUpdate }: UpdateUserModalProps) => {
  const [form] = Form.useForm();
  const [loading, setLoadling] = useState(false);

  const onFinish = async () => {
    try {
      setLoadling(true);
      const values = await form.validateFields();
      await updateMember(context.data.doc_id, values);
      onCancel();
      Modal.success({
        title: 'Add member successfully',
        onOk: onUpdate,
      });
    } catch (error) {
      console.log('validate failed', error);
      Modal.error({
        title: 'Add member failed',
      });
    } finally {
      setLoadling(false);
    }
  };

  const onCancel = () => {
    context.close();
    form.resetFields();
  }

  useEffect(() => {
    form.setFieldsValue(context.data);
  }, [context.data]);

  return (
    <Modal
      visible={context.visible}
      title="Update member"
      onCancel={onCancel}
      onOk={onFinish}
      confirmLoading={loading}
      destroyOnClose
    >
      <Form
        layout="vertical"
        form={form}
        name="update_user_form"
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              label="Display name"
              name="display_name"
              fieldKey="display_name"
              rules={[{ required: true, message: 'Please enter display name' }]}
            >
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Class"
              name="main_class"
              fieldKey="main_class"
              rules={[{ required: true, message: 'Please select class' }]}
            >
              <Select
                options={[
                  { label: 'Warrior', value: Class.WARRIOR },
                  { label: 'Sorceress', value: Class.SORCERESS },
                  { label: 'Taolist', value: Class.TAOIST },
                  { label: 'Lancer', value: Class.LANCER },
                  { label: 'Arbalist', value: Class.ARBALIST },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Combat point (CP)"
              name="combat_point"
              fieldKey="combat_point"
              rules={[{ required: true, message: 'Please enter combat point' }]}
            >
              <InputNumber style={{ width: '100%' }}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Status"
              name="status"
              fieldKey="status"
              rules={[{ required: true, message: 'Please select status' }]}
            >
              <Select
                defaultValue={MemberStatus.ACTIVE}
                options={Object.keys(MemberStatus)
                  .map((status) => ({ label: capitalize(status), value: status, disabled: status === MemberStatus.DISCARDED }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default UpdateUserModal;
