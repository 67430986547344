export enum Class {
  WARRIOR = 'WARRIOR',
  SORCERESS = 'SORCERESS',
  TAOIST = 'TAOIST',
  LANCER = 'LANCER',
  ARBALIST = 'ARBALIST',
}

export enum MemberStatus {
  ACTIVE = 'ACTIVE',
  DISCARDED = 'DISCARDED',
  PENDING = 'PENDING',
}