import { Button, Card, Col, Modal, Row, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import Member from "../../types/Member";
import { DeleteOutlined, EditOutlined, UserAddOutlined } from "@ant-design/icons";
import useModal from "../../hooks/useModal";
import AddUserModal from "./components/AddUserModal";
import useAuth from "../../hooks/useAuth";
import ClassTag from "../../components/ClassTag";
import UpdateUserModal from "./components/UpdateUserModal";
import { getMembers } from "../../repositories/member";
import { updateMember } from "../../repositories/member/member.firebase.repository";
import { MemberStatus } from "../../types/enum";
import { capitalize } from "lodash";

const STATUS_COLOR: Record<MemberStatus, string> = {
  [MemberStatus.ACTIVE]: 'green',
  [MemberStatus.DISCARDED]: 'gray',
  [MemberStatus.PENDING]: 'warning',
}

const UserPage = () => {
  useAuth();
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState<Member[]>([]);

  const addUserModal = useModal();
  const updateUserModal = useModal();

  const fetchUsers = async () => {
    try {
      setLoading(true);
      setMembers(await getMembers(true));
    } finally {
      setLoading(false);
    }
  };

  const onUpdateBtnClicked = (member: any) => () => {
    updateUserModal.set(member);
    updateUserModal.open();
  }

  const onDeleteBtnClicked = ({ doc_id, display_name }: any) => () => {
    Modal.confirm({
      title: `Are you want to discard ${display_name}`,
      onOk: async () => {
        await updateMember(doc_id, { status: MemberStatus.DISCARDED });
        await fetchUsers();
      },
    });
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <AddUserModal context={addUserModal} onCreate={fetchUsers}/>
      <UpdateUserModal context={updateUserModal} onUpdate={fetchUsers}/>
      <Card>
        <Row gutter={[16,16]}>
          <Col span={24}>
            <Row justify="end">
              <Button
                type="primary"
                icon={<UserAddOutlined/>}
                onClick={() => addUserModal.open()}
              >
                Add member
              </Button>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              pagination={false}
              loading={loading}
              dataSource={members.map(({ updated_at, combat_point, status, ...user }, index) => ({
                key: `${index}`,
                ...user,
                updated_at: updated_at.toDate().toLocaleString(),
                combat_point: combat_point.toLocaleString(),
                status: status || MemberStatus.ACTIVE,
              }))}
              columns={[
                { title: 'Display name', dataIndex: 'display_name', key: 'display_name' },
                { title: 'Class', dataIndex: 'main_class', key: 'main_class', render: (text) => (<ClassTag memberClass={text}/>) },
                { title: 'Combat point (CP)', dataIndex: 'combat_point', key: 'combat_point' },
                { title: 'Last update', dataIndex: 'updated_at', key: 'updated_at' },
                {
                  title: 'Status',
                  dataIndex: 'status',
                  key: 'status',
                  render: (text: MemberStatus) => (<Tag color={STATUS_COLOR[text] || 'default'}>{capitalize(text)}</Tag>),
                  filters: Object.keys(MemberStatus).map((value) => ({ text: capitalize(value), value })),
                  onFilter: (value: any, record) => record.status.indexOf(value) === 0,
                  defaultFilteredValue: [MemberStatus.ACTIVE],
                },
                { render: (_, record, index) => (
                  <Row gutter={8}>
                    <Col><Button onClick={onUpdateBtnClicked(record)} icon={<EditOutlined/>}/></Col>
                    <Col><Button onClick={onDeleteBtnClicked(record)} icon={<DeleteOutlined/>}/></Col>
                  </Row>
                )},
              ]}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default UserPage;