import { Button, Card, Col, Form, Row, Table, Select, Modal } from "antd";
import { isNil, sumBy, omit, toNumber } from 'lodash';
import { useEffect, useState } from "react";
import Member from "../../types/Member";
import { addDoc, collection } from "@firebase/firestore";
import { db } from "../../externals/firebase";
import ClassTag from "../../components/ClassTag";
import { CloseCircleOutlined, PlusCircleOutlined, ShareAltOutlined } from "@ant-design/icons";
import { getMembers } from "../../repositories/member";

interface TeamMember extends Member {
  partyIndex?: number
  id: number;
}

const CreatePartyPage = () => {
  const [loading, setLoading] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [members, setMembers] = useState<TeamMember[]>([]);

  const onSelectParty = (record: TeamMember, partyIndex?: number) => {
    const index = members.findIndex((member) => member.id === record.id);
    members[index].partyIndex = partyIndex;
    setMembers([...members]);
  };

  const onFinish = async (values: { party: any[] }) => {
    try {
      setSharing(true);
      const parties = values.party.reduce((result, _, partyIndex) => {
        result[partyIndex] = members.filter((member) => member.partyIndex === partyIndex)
          .map((member) => ({ ...omit(member, ['partyIndex', 'id', 'key', 'updated_at']) }));
        return result;
      }, {} as any);
      await addDoc(
        collection(db, 'parties'),
        {
          parties,
        }
      );
      Modal.success({
        title: 'Share successfully',
        onOk: () => { window.location.reload(); },
      });
    } catch (error) {
      console.log('failed');
      Modal.error({
        title: 'Failed to share',
      });
    } finally {
      setSharing(false);
    }
  }

  const fetchUsers = async () => {
    try {
      setLoading(true);
      setMembers((await getMembers()).map((member, index) => ({ ...member, id: index })));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Card title="Create Party">
      <Col span={24}>
        <Form
          name="config_party"
          layout="vertical"
          onFinish={onFinish}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row justify='end'>
                <Button loading={sharing} icon={<ShareAltOutlined/>} type="primary" htmlType="submit">Share</Button>
              </Row>
            </Col>
            <Form.List name="party">
              {(fields, { add, remove }) => (
                <>
                  <Col span={12}>
                    <Row gutter={[16, 16]}>
                      {fields.map((_, index) => {
                        const filteredMembers = members.filter(({ partyIndex }) => partyIndex === index);
                        const totalCP = sumBy(filteredMembers, 'combat_point');
                        return (
                          <Col span={24}>
                            <Card
                              // extra={
                              //   <Button
                              //     onClick={() => {
                              //       const result = filteredMembers.reduce((newMembers, member) => {
                              //         const memberIndex = members.findIndex((m) => m.id === member.id);
                              //         newMembers[memberIndex].partyIndex = undefined;
                              //         return newMembers;
                              //       }, members);
                              //       setMembers([...result]);
                              //       remove(index);
                              //     }}
                              //     icon={<CloseOutlined/>}
                              //   />
                              // }
                              title={`Party ${index + 1} - ${totalCP.toLocaleString()} (${filteredMembers.length ? (totalCP/filteredMembers.length).toLocaleString('th', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0})`}
                            >
                              <Table
                                pagination={false}
                                dataSource={filteredMembers.map(({ updated_at, combat_point, ...user }, index) => ({
                                  key: user.id,
                                  ...user,
                                  updated_at: updated_at.toDate().toLocaleString(),
                                  combat_point: combat_point,
                                }))}
                                columns={[
                                  { title: 'Display name', dataIndex: 'display_name', key: 'display_name' },
                                  {
                                    title: 'Class',
                                    dataIndex: 'main_class',
                                    key: 'main_class',
                                    render: (text) => (<ClassTag memberClass={text}/>),
                                    defaultSortOrder: 'descend',
                                    sortDirections: ['descend', 'ascend'],
                                    sorter: (a, b) => {
                                      const classA = a.main_class.toUpperCase();
                                      const classB = b.main_class.toUpperCase();
                                      
                                      if (classA === classB) {
                                        return 0;
                                      }
                                      return classA > classB ? 1 : -1;
                                    },
                                  },
                                  {
                                    title: 'Combat point (CP)',
                                    dataIndex: 'combat_point',
                                    key: 'combat_point',
                                    width: 150,
                                    defaultSortOrder: 'descend',
                                    sortDirections: ['descend', 'ascend'],
                                    sorter: (a, b) =>  toNumber(a.combat_point) - toNumber(b.combat_point),
                                    render: (text) => text.toLocaleString(),
                                  },
                                  { render: (_, record) => (<Button onClick={() => onSelectParty(record as any)} type="text" icon={<CloseCircleOutlined/>}></Button>)}
                                ]}
                              />
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                    <Form.Item>
                      <Button type="text" icon={<PlusCircleOutlined/>} onClick={() => add()}>Add party</Button>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Card>
                      <Table
                        pagination={false}
                        loading={loading}
                        dataSource={members.filter(({ partyIndex }) => isNil(partyIndex)).map(({ updated_at, combat_point, ...user }, index) => ({
                          key: user.id,
                          ...user,
                          updated_at: updated_at.toDate().toLocaleString(),
                          combat_point: combat_point,
                        }))}
                        columns={[
                          { title: 'Display name', dataIndex: 'display_name', key: 'display_name' },
                          {
                            title: 'Class',
                            dataIndex: 'main_class',
                            key: 'main_class',
                            defaultSortOrder: 'descend',
                            sortDirections: ['descend', 'ascend'],
                            sorter: (a, b) => {
                              const classA = a.main_class.toUpperCase();
                              const classB = b.main_class.toUpperCase();
                              
                              if (classA === classB) {
                                return 0;
                              }
                              return classA > classB ? 1 : -1;
                            },
                            render: (text) => (<ClassTag memberClass={text}/>),
                          },
                          {
                            title: 'Combat point (CP)',
                            dataIndex: 'combat_point',
                            key: 'combat_point',
                            width: 150,
                            defaultSortOrder: 'descend',
                            sortDirections: ['descend', 'ascend'],
                            sorter: (a, b) => toNumber(a.combat_point) - toNumber(b.combat_point),
                            render: (text) => text.toLocaleString(),
                          },
                          {
                            render: (_, record) => (
                              <Select
                                onChange={(id) => onSelectParty(record as any, id)}
                                options={fields.map((_, partyIndex) => ({
                                  label: `Party ${partyIndex + 1}`,
                                  value: partyIndex,
                                  key: `${partyIndex}`,
                                }))}
                                value={record.partyIndex}
                                style={{ width: '100%' }}
                              />
                            )
                          },
                        ]}
                      />
                    </Card>
                  </Col>
                </>
              )}
            </Form.List>
          </Row>
          
        </Form>
      </Col>
    </Card>
  );
};

export default CreatePartyPage;
