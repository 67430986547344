import { useParams } from "react-router";
import { doc, getDoc } from "@firebase/firestore";
import { Card, Col, Row, Table, Spin } from "antd";
import {  useEffect, useState } from "react";
import { db } from "../../externals/firebase";
import MemberReport from "../../types/MemberReport";
import ClassTag from "../../components/ClassTag";

const ViewUserReportPage = ({}) => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<MemberReport>();

  const fetch = async () => {
    try {
      setLoading(true);
      const docSnapshot = await getDoc(doc(db, 'member_reports', `${params.id}`))
      setData(docSnapshot.data() as MemberReport);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  if (!data) {
    return (<Spin/>)
  }

  return (
    <Card title="User Report">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {data?.title}
        </Col>
        <Col span={24}>
          <Table
            pagination={false}
            loading={loading}
            dataSource={data?.members.map(({ updated_at, combat_point, ...user }, index) => ({
              key: `${index}`,
              ...user,
              updated_at: updated_at.toDate().toLocaleString(),
              combat_point: combat_point.toLocaleString(),
            }))}
            columns={[
              { title: 'Display name', dataIndex: 'display_name', key: 'display_name' },
              { title: 'Class', dataIndex: 'main_class', key: 'main_class', render: (text) => (<ClassTag memberClass={text}/>) },
              { title: 'Remark', dataIndex: 'remark', key: 'remark' },
            ]}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default ViewUserReportPage;
