import { Button, Col, Layout as AntdLayout, Menu, Row } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import {
  FileOutlined,
  LoginOutlined,
  LogoutOutlined,
  PartitionOutlined,
  PieChartOutlined,
  PlusOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { BrowserRouter, Link, Routes } from 'react-router-dom';
import logo from "../../assets/images/logo.png";
import styles from './Layout.module.scss';
import { version } from '../../../package.json';
import { auth } from '../../externals/firebase';
import { User } from '@firebase/auth';

const { SubMenu } = Menu;

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const [user, setUser] = useState<User | null>();
  auth.onAuthStateChanged((u) => {
    setUser(u);
  });

  return (
    <BrowserRouter>
      <AntdLayout className={styles.container}>
        <AntdLayout.Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
            <Row align="bottom">
              <Col span={24}>
                <Link to="/">
                  <img width="100%" src={logo} alt="logo" />
                </Link>
                <div><span style={{ color: 'white'}}>{version}</span></div>
              </Col>
            </Row>
          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
            <Menu.Item key="members" icon={<TeamOutlined />}>
              <Link to="/members">Clan members</Link>
            </Menu.Item>
            <SubMenu key="report" icon={<FileOutlined/>} title="Report">
              <Menu.Item key="report:1">
                <Link to="/report/user">User report</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="party" icon={<PartitionOutlined/>} title="Party">
              <Menu.Item key="party:create" icon={<PlusOutlined/>}>
                <Link to="/party/create">Create party</Link>
              </Menu.Item>
            </SubMenu>
            {user ? (
              <SubMenu key="user" icon={<UserOutlined/>} title={user.displayName}>
                <Menu.Item key="user:logout" icon={<LogoutOutlined/>}>
                  <Link to="logout">Logout</Link>
                </Menu.Item>
              </SubMenu>
            ) : (
              <Menu.Item key="login" icon={<LoginOutlined/>}>
                <Link to="login">Login</Link>
              </Menu.Item>
            )}
          </Menu>
        </AntdLayout.Sider>
        <AntdLayout>
          <AntdLayout.Content style={{ padding: 32, overflow: 'auto' }}>
            <Routes>
              {children}
            </Routes>
          </AntdLayout.Content>
        </AntdLayout>
      </AntdLayout>
    </BrowserRouter>
  );
};

export default Layout;
