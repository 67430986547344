import { Tag, TagProps } from "antd";
import { PresetColorType, PresetStatusColorType } from "antd/lib/_util/colors";
import { LiteralUnion } from "antd/lib/_util/type";
import { capitalize } from 'lodash';
import { Class } from "../../types/enum";

interface ClassTagProps extends TagProps {
  memberClass: Class;
}

const CLASS_COLOURS: Record<Class,  LiteralUnion<PresetColorType | PresetStatusColorType, string>> = {
  [Class.WARRIOR]: 'red',
  [Class.SORCERESS]: 'yellow',
  [Class.TAOIST]: 'green',
  [Class.LANCER]: 'blue',
  [Class.ARBALIST]: 'lime',
}

const ClassTag = ({ memberClass, children, ...props }: ClassTagProps) => {
  return (
    <Tag color={CLASS_COLOURS[memberClass]} {...props}>{children || capitalize(memberClass)}</Tag>
  );
};

export default ClassTag;
