import { SaveOutlined } from "@ant-design/icons";
import { addDoc, collection } from "@firebase/firestore";
import { Button, Card, Col, Input, Row, Table, Form, Modal } from "antd";
import React, { ChangeEventHandler, useEffect, useState } from "react";
import ClassTag from "../../components/ClassTag";
import { db } from "../../externals/firebase";
import useAuth from "../../hooks/useAuth";
import { getMembers } from "../../repositories/member";
import { MemberReportItem } from "../../types/MemberReportItem";

const UserReportPage = () => {
  useAuth();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [members, setMembers] = useState<MemberReportItem[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      setMembers(await getMembers());
    } finally {
      setLoading(false);
    }
  };

  const onRemarkChange: (index: number) => ChangeEventHandler = (index: number) => (e) => {
    const target: any = e.target
    members[index].remark = target.value;
    setMembers([...members]);
  }

  const onSave = async (values: any) => {
    try {
      setSaving(true);
      const doc = await addDoc(
        collection(db, 'member_reports'),
        {
          ...values,
          members: members.filter((_, index) => selectedKeys.includes(`${index}`)),
          created_at: new Date(),
        }
      );
      Modal.info({
        title: 'Save and share report succesfully!',
        content: (
          <span>
            Share with this 
            <a href={`${window.location.origin}/report/user/${doc.id}`}> link</a>
          </span>
        )
      })
    } catch (error) {
      
    } finally {
      setSaving(false);
    }
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Card title="User Report">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form onFinish={onSave} layout="inline">
            <Form.Item
              name="title"
              fieldKey="title"
            >
              <Input/>
            </Form.Item>
            <Row justify="end">
              <Button loading={saving} type="primary" htmlType="submit" icon={<SaveOutlined/>}>Save and Share</Button>
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <Table
            pagination={false}
            rowSelection={{
              type: 'checkbox',
              onChange: (keys: React.Key[]) => {
                setSelectedKeys(keys);
              },
            }}
            loading={loading}
            dataSource={members.map(({ updated_at, combat_point, ...user }, index) => ({
              key: `${index}`,
              ...user,
              updated_at: updated_at.toDate().toLocaleString(),
              combat_point: combat_point.toLocaleString(),
            }))}
            columns={[
              { title: 'Display name', dataIndex: 'display_name', key: 'display_name' },
              { title: 'Class', dataIndex: 'main_class', key: 'main_class', render: (text) => (<ClassTag memberClass={text}/>) },
              { title: 'Combat point (CP)', dataIndex: 'combat_point', key: 'combat_point' },
              { title: 'Last update', dataIndex: 'updated_at', key: 'updated_at', },
              {
                title: 'Remark',
                dataIndex: 'remark',
                key: 'remark',
                render: (text, _, index) => (
                  <Input value={text} onChange={onRemarkChange(index)} />
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default UserReportPage;
