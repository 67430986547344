import logo from './logo.svg';
import './App.css';
import firebaseui from 'firebaseui';
import { GoogleAuthProvider, signInWithPopup, User } from 'firebase/auth';
import { auth, db, provider } from './externals/firebase';
import { useState } from 'react';
import { getDocs, collection } from 'firebase/firestore';
import Layout from './components/Layout';
import 'antd/dist/antd.css';
import { Route } from 'react-router-dom';
import UserPage from './pages/UserPage';
import UserReportPage from './pages/UserReportPage';
import ViewUserReportPage from './pages/ViewUserReportPage';
import LogoutPage from './pages/LogoutPage';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import CreatePartyPage from './pages/CreatePartyPage';

const App = () => {
  return (
    <Layout>
      <Route
        path="/"
        element={<HomePage/>}
      />
      <Route path="/members" element={<UserPage/>} />
      <Route path="/report/user" element={<UserReportPage/>}/>
      <Route path="/report/user/:id" element={<ViewUserReportPage/>} />
      <Route path="logout" element={<LogoutPage/>} />
      <Route path="login" element={<LoginPage/>} />
      <Route path="party/create" element={<CreatePartyPage/>}/>
    </Layout>
  );
}

export default App;
