import { GoogleOutlined } from "@ant-design/icons";
import { GoogleAuthProvider, signInWithPopup } from "@firebase/auth";
import { Button, Card } from "antd";
import { useNavigate } from "react-router";
import { auth, provider } from "../../externals/firebase";

const LoginPage = () => {
  const navigate = useNavigate();
  const login = async () => {
    try {
      await signInWithPopup(auth, provider);
      navigate('/');
    } catch (error: any) {
      console.log(error);
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
    }
  }

  return (
    <Card>
      <Button icon={<GoogleOutlined/>} onClick={login}>Sign in</Button>
    </Card>
  )
};

export default LoginPage;
