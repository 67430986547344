import { addDoc, collection, doc, getDocs, query, where, writeBatch } from "@firebase/firestore";
import { db } from "../../externals/firebase";
import { MemberStatus } from "../../types/enum";
import Member from "../../types/Member";

const dbName = 'clan_members';

export const getMembers = async (includedDiscardMember?: boolean): Promise<Member[]> => {
  const members: Member[] = [];
  const q = includedDiscardMember ? collection(db, dbName) : query(collection(db, dbName), where('status', '==', MemberStatus.ACTIVE));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    members.push({
      ...doc.data() as Member,
      doc_id: doc.id,
    });
  });
  return members;
};

export const createMember = async (member: Member) => {
  await addDoc(
    collection(db, dbName),
    {
      ...member,
      updated_at: new Date(),
    },
  );
};

export const updateMember = async (id: string, member: Partial<Member>) => {
  const batch = writeBatch(db);
  const ref = doc(db, dbName, id);
  batch.update(ref, { ...member, updated_at: new Date() });
  await batch.commit();
};
