import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCwt1cD8x4Ls4B88wuYpY_Nsgd6Yo3VLT8",
  authDomain: "mir4-management.firebaseapp.com",
  projectId: "mir4-management",
  storageBucket: "mir4-management.appspot.com",
  messagingSenderId: "972046524216",
  appId: "1:972046524216:web:0d0d29e9acce7cce8c6bcd",
  measurementId: "G-MFGRGSYD37"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore();