import { signOut } from "@firebase/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { auth } from "../../externals/firebase";

const LogoutPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    signOut(auth)
      .then(() => navigate('/'));
  }, []);

  return (
    <div>Logging out</div>
  );
};

export default LogoutPage;
